export const collages = [
  "Indian Institute of Technology, Bombay",
  "Indian Institute of Management, Bangalore Other",
  "Other",
];

export const positions = [
  "Patenting Internship",
  "Iterative Design Internship",
  "Liaising Internship",
  "Launch Planning Internship",
  "Advangardes Internsip",
  "Innovations Internsip",
  "Other",
];

export const departments = [
  "Patenting Department",
  "Iterative-Design Department",
  "Liasing Department",
  "Launch-Planning Department",
  "Advant-gardes Department",
  "Innovations Department",
  "Other",
];
